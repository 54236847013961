<template>
  <v-container>
    <v-row justify="space-around">
      <v-card width="600">
        <v-img
          height="300px"
          contain = true
          :src="require('../assets/' + propImg)"
        >

        </v-img>

        <v-card-text>
          <div class="font-weight-bold ml-8 mb-2">
            {{ propTitle }}
          </div>

          <v-timeline
            align-top
            dense
          >
            <v-timeline-item
              v-for="message in propMessages"
              :key="message.id"
              :color="message.color"
              small
            >
              <div>
                <div>{{ message.message }}</div>
              </div>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'WhatWeDo',
    props: ['propImg', 'propTitle', 'propMessages']
  }
</script>
