<template>
  <v-container fluid>
    <v-card-text> </v-card-text>
    <v-row>
      <v-col v-for="item in list" :key="item.id">
        <WhatWeDo :propImg="item.displayImg"
                  :propTitle="item.displayTitle"
                  :propMessages="item.messages" />
      </v-col>
    </v-row>
    <v-card-text> </v-card-text>
    <v-col cols="12">
        <v-card>
          <v-card-text class="font-weight-bold mb-2">
              Contact Us
          </v-card-text>
          
          <v-divider></v-divider>

          <v-card-text>
              <span class="font-weight-bold mb-2">Mobile:</span> +44 7982 594 224
          </v-card-text>

          <v-card-text>
              <span class="font-weight-bold mb-2">email:</span> <a href="mailto:contactus@bytesizeitsolutions.com">contactus@bytesizeitsolutions.com</a>
          </v-card-text>

        </v-card>
    </v-col>
  </v-container>
</template>

<script>
import WhatWeDo from "../components/WhatWeDo.vue";

export default {
  components: { WhatWeDo },
  data() {
    return {
      list: [
        {
          id: "1",
          displayImg: "ITSolution.png",
          displayTitle: "IT / Digital Solutions",
          messages: [
            {
              id: "1",
              message: `Do you run a business? Getting overwhelmed with manual process? Wish you have an IT solution, however do not want to enter into an IT project?`,
              color: "deep-purple lighten-1",
            },
            {
              id: "2",
              message: "If your answer is 'Yes' to any of the questions above, then contact us. We can help without time consuming (or) costly IT implementation.",
              color: "green",
            },
            {
              id: "3",
              message: "Still not sure if you can get some help for your business through IT / digital solution (or) automation? Contact us, we will work with you to help assess if you can benefit from an IT solution. Our consultation is free",
              color: "deep-purple lighten-1",
            },
          ],
        },
        {
          id: "2",
          displayImg: "MobileAppDev.png",
          displayTitle: "Mobile Application Development",
          messages: [
            {
              id: "1",
              message: `Have an awesome Idea?
              Want to create a mobile app for your business?
              Don't know where to start?`,
              color: "deep-purple lighten-1",
            },
            {
              id: "2",
              message: `Contact us, we will work with you to get you started 
              on your mobile app creation journey.`,
              color: "green",
            },
            {
              id: "3",
              message: `It's alright to have an idea and not knowing where to start. 
              Start by contacting us, we are always up for discussion with 
              fantastic, energetic people like you`,
              color: "deep-purple lighten-1",
            },
          ],
        },
        {
          id: "3",
          displayImg: "GameDev.png",
          displayTitle: "Game Development",
          messages: [
            {
              id: "1",
              message: `Have you ever thought, 'If only I knew to develop a Mobile Game...' 
              Well, your idea is all that matters`,
              color: "deep-purple lighten-1",
            },
            {
              id: "2",
              message: "Come talk with us, we can explain to you what it takes to build a game. What skills are needed, what resources are needed, we can explain everything.",
              color: "green",
            },
            {
              id: "3",
              message: "There on, you can do a project (or) we can build it with you (or) we can put you in touch with people who can help you with creating what you need.",
              color: "deep-purple lighten-1",
            },
          ],
        },
      ],
    };
  },
};
</script>
