<template>
  <v-app>
    <v-app-bar app class="appbar lighten-1" dark>
      <div class="d-flex align-center">
        <v-img
          alt="Byte Size ITS Logo"
          class="shrink mr-2 mt-2"
          contain
          src="./assets/logo.png"
          transition="scale-transition"
          width="250"
        />
      </div>

      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>

      <v-footer dark padless>
        <v-col cols="12" class="appbar">
        <v-card flat tile class="transparent lighten-1 white--text text-center">
          <v-card-text> </v-card-text>

          <v-card-text class="white--text pt-0">
            <a href="https://www.freepik.com/vectors/design"
              target="_blank"
              class="acolour">Design vector used in this website are created by freepik - www.freepik.com</a
            >
          </v-card-text>

          <v-divider></v-divider>

          <v-card-text class="white--text">
            {{ new Date().getFullYear() }} —
            <strong>Byte Size IT Solutions</strong>
          </v-card-text>
        </v-card>
        </v-col>
      </v-footer>

  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>

<style>
.appbar {
  background: linear-gradient(
    90deg,
    rgba(74, 3, 159, 1) 0%,
    rgba(205, 65, 186, 1) 50%,
    rgba(186, 79, 2, 1) 100%
  );
}
.acolour {
  color: white !important;
  font-size: 0.75rem;
}
</style>
